<template>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      > 

      <v-text-field
          v-model="userInfo.name"          
          v-if="hasName"
          label="Name"
          :rules="[
            required('name')
          ]"          
        />   

        <v-text-field
          v-model="userInfo.email"          
          label="E-mail"
          :rules="[
            required('email'),
            invalidEmail('email')
          ]"
        />
        
<v-row>
  <v-col cols=12>
    <v-text-field
          v-model="userInfo.password"
          v-if="hasPass"
          label="Password"                 
          color="cyan darken"
          :counter="7"          
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text':'password'"          
          :rules="[
              required('password'),
              minLength('password',7)
            ]"
          @click:append="showPassword = !showPassword"           
          loading
        >
          <!-- <template v-slot:progress>
            <v-progress-linear
              :value="progress"
              :color="color"
              height="7"
            ></v-progress-linear>
          </template> -->
    </v-text-field>

  </v-col>
</v-row>
        

        <v-checkbox
          v-model="checkbox"
          v-if="hasRegister"
          label="Usted es usuario del eCommerce?"
          :rules="[accept('condiciones')]"
        ></v-checkbox>

        <v-row>
          <v-col cols=12 sm=12 md=6 lg=6>
            <v-btn
              v-if="hasLogin"
              color="success"
              class="mr-4"
              @click="submitForm(userInfo)" 
              :disabled="!valid"
            >
              {{buttonText}}
            </v-btn>    
          </v-col>
          <v-col cols=12 sm=12 md=6 lg=6>
            <v-btn 
              to="/forget"
              v-if="hasLogin"
            >          
              <v-icon>lock_open</v-icon> Olvide Password
            </v-btn>
          </v-col>
        </v-row>   

                
        <v-btn
          v-if="hasBoton"
          color="error"
          class="mr-4"
          @click="reset"
        >
          Reset Form
        </v-btn>

        <v-btn
          v-if="hasBoton"
          color="warning"
          @click="resetValidation"
        >
          Reset Validation
        </v-btn>         
      </v-form>

      

      
</template>

<script>
// import{ mapState } from "vuex";
import validations from '@/utils/validation';


export default {
  name: 'UserAuthForm',  
  components: {
  },  
  computed: {
      progress () {
        return Math.min(100, this.userInfo.password.length * 12)
      },
      color () {
        return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
      }
  },  
  methods:{
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }    
  },
  props:["submitForm","buttonText","hasLogin","hasBoton","hasName","hasPass","hasAuth","hasRegister"],
  data(){
     return{
        ...validations,
        valid:true,
        showPassword: false,        
        checkbox: true,
        userInfo:{
          email:'',
          password:''
        }
     }
  }  
}
</script>          
