<template>
<div id="EventForm">  
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <v-row>  
        <v-col cols="12" sm="6" md="4">
          <!-- v-if="hasCurrency" -->
                <v-text-field
                  v-model="rowInfo.tipo_cambio"
                  label="Tipo de Cambio*"
                  :rules="[
                    required('tipo de cambio')
                  ]"       
                />                
        </v-col>
        <v-col cols="12" sm="6" md="4">
              <v-combobox
                  v-model="rowInfo.abreviada" 
                  :items="itemsSiglas"
                  label="Seleccione una sigla*"
                  :rules="[
                    required('tipo de cambio')
                  ]"
                ></v-combobox>            
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-combobox
                v-model="rowInfo.status" 
                :items="itemsStatus"
                label="Seleccione un status*"
                :rules="[
                    required('tipo de cambio')
                  ]"
            ></v-combobox>               
        </v-col>
      </v-row>        

  <v-btn
          color="primary"
          class="mr-4"
          @click="submitForm(rowInfo)" 
          :disabled="!valid"
  >
        <v-icon>save</v-icon> {{buttonText}}
  </v-btn>        
  <v-btn
          color="error"
          class="mr-4"
          @click="reset"
        >
          <v-icon>cleaning_services</v-icon> Reset Form
        </v-btn>        
  </v-form>
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import validations from '@/utils/validation';

export default {
  name: 'EventForm',  
  components: {
  },  
  computed:{
    ...mapState(['customers'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){      
      try{       
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        // this.$store.dispatch('loadPricelists',"All"); 
        this.attribute.sword="All";
        this.$store.dispatch('loadCustomers',this.attribute);
      }catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      }
    },
    reset () {
        this.$refs.form.reset()
    }
  },
  props:["submitForm","buttonText",'hasEvent','hasBrand','hasMeasurement',"hasSalesOrder","hasQuote","hasDescription"],
  data(){
     return{
        valid: false,
        ...validations,
        attribute: {sword: '',limit: 27},
        // pricelists:[],
        rowInfo:{ 
          tipo_cambio: '',          
          abreviada:"",
          status: '', 
          fecha_inicio:new Date().toISOString().substr(0,10)+" 00:00:00",
          fecha_fin:new Date().toISOString().substr(0,10)+" 00:00:00",
        },
        itemsStatus:[        
          'Venta',
          'Compra',
        ],
        itemsSiglas:[
          'MXN',
          'USD',
          'EUR',
        ],
     }
  },
  created(){
    this.getPrice2()    
  }  
}
</script>          
