<template>
  <div class="settings"> 

    <v-list-item>
          <v-badge            
            bottom
            color="green accent-4"
            dot
            offset-x="25"
            offset-y="20"
          >
          <v-list-item-avatar v-if='currentUser.profile_avatar != null'>
                    <v-img
                      v-bind:lazy-src="currentUser.profile_avatar"                             
                      v-bind:src="currentUser.profile_avatar"                      
                      max-height="150"
                      max-width="150"
                    ></v-img>                 
          </v-list-item-avatar>     
          <v-list-item-avatar v-else>                    
                    <v-img
                      v-bind:lazy-src="currentUser.branch_filename"                      
                      max-height="150"
                      max-width="150"
                      v-bind:src="currentUser.branch_filename"                      
                    ></v-img>    
          </v-list-item-avatar>
        </v-badge>
                                        
           <v-list-item-content>
             <v-list-item-title>  {{currentUser.name}}</v-list-item-title>
             <v-list-item-subtitle>  {{currentUser.email}}</v-list-item-subtitle>
           </v-list-item-content>
      </v-list-item>

    <!-- MENU -->
    <template>
      <v-card flat  class="py-1" >
                            <v-card-text >
                              <v-row class="text-right" justify="center" >        
                                <v-btn-toggle
                                  v-model="toggle_exclusive"
                                  mandatory
                                >
                                  
                                
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }"> 
                                        <v-btn
                                          color="green"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-dialog
                                            v-model="dialog"
                                            persistent
                                            max-width="600px"                 
                                          >
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn
                                                color="green"            
                                                v-bind="attrs"
                                                v-on="on"
                                                plain
                                              >
                                                <v-icon>account_balance</v-icon>
                                              </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                  <span class="headline">Agregar Tipo de Cambio</span>
                                                  <v-btn
                                                    class="headline"
                                                    color="blue darken-1"
                                                    text
                                                    @click="dialog = false"
                                                  >
                                                    <v-icon>close</v-icon>
                                                  </v-btn>
                                                </v-card-title>
                                                <v-card-text>
                                                  <v-container>  
                                                    <CurrencyForm :submitForm="AddCurrency" buttonText="Guardar" hasEvent=true />            
                                                  </v-container>
                                                  
                                                </v-card-text>
                                                <v-card-actions>
                                                  <v-spacer></v-spacer>          
                                                  <small style="color:red;">*Campos Obligatorios</small>
                                                  <!-- <v-btn 
                                                    color="blue darken-1"
                                                    text
                                                    v-on:click="submit"><v-icon>save</v-icon>
                                                  </v-btn>         -->
                                                </v-card-actions>
                                              </v-card>
                                              </v-dialog>
                                        </v-btn>                
                                      </template>
                                      <span>Agregar Precio</span>
                                    </v-tooltip>
                                </template>   
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="getSetting('')" 
                      color="primary darken-5"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Refrescar Página</span>
                </v-tooltip>
            </template>
                                
                        
            
                                          
                                <template>  
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">              
                                        <v-btn
                                          @click="$router.back()" 
                                          color="primary darken-2"               
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                        <v-icon>arrow_back</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Regresar a la Página Anterior</span>
                                    </v-tooltip>
                                </template>
                                  
                                  
                                </v-btn-toggle>
                              </v-row>
                            </v-card-text>
      </v-card>                          
    </template>
    <!-- FIN Menu -->

     <v-row>
      <v-col cols="12" sm="12" md="6" lg="4">
        <template>
            <v-row justify="center">
              <v-col cols="12">
                <v-card 
                  ref="form"
                  elevation="8"
                  outlined
                >
                  <v-card-title>Perfil</v-card-title>
                  <v-card-text>
                    <v-text-field
                      ref="name"
                      v-model="profile.fullname"
                      label="Nombre Completo"
                      placeholder="Emmanuel Carreon"
                      readonly
                      ></v-text-field>
                      <v-text-field
                      ref="address"
                      v-model="profile.nickname"
                      label="Nickname"
                      placeholder="emmane"      
                      v-on:keyup.13="updateRowProfile(profile)"
                    ></v-text-field>
                    <v-text-field
                      ref="city"
                      v-model="profile.avatar"
                      label="Url de Avatar"
                      placeholder="url avatar"
                      v-on:keyup.13="updateRowProfile(profile)"
                      required
                    ></v-text-field>
                    <v-text-field
                      ref="state"
                      v-model="profile.address"
                      label="Domicilio"
                      placeholder="16 Septiembre 33"
                      v-on:keyup.13="updateRowProfile(profile)"             
                      required
                    ></v-text-field>
                    <!-- :rules="[() => !!zip || 'This field is required']" -->
                    <!-- required -->
                    <v-text-field
                      ref="zip"
                      v-model="profile.birthday"
                      label="Cumpleaños"
                      placeholder="27-03-1986"
                      v-on:keyup.13="updateRowProfile(profile)"
                    ></v-text-field>
                    <v-text-field
                      ref="zip"
                      v-model="profile.phone"
                      label="Telefono"
                      placeholder="2305349"
                      v-on:keyup.13="updateRowProfile(profile)"
                    ></v-text-field>
                    <v-text-field
                      ref="zip"
                      v-model="profile.mobile"
                      label="Celular"
                      placeholder="2225323152"
                      v-on:keyup.13="updateRowProfile(profile)"
                    ></v-text-field>
                   
                  </v-card-text>
                  <br><br>

                  <span class="mx-4">
                    Ultima Actualizacion: {{ formatDate(profile.updated_at) }}
                  </span>

                  <v-divider class="mt-12"></v-divider>
                  <v-card-actions>
                    <v-btn text>
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-slide-x-reverse-transition>
                      <v-tooltip
                        v-if="formHasErrors"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="my-0"
                            v-bind="attrs"
                            @click="resetForm"
                            v-on="on"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Refresh form</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                    <v-btn v-on:click="updateRowProfile(profile)" class="green">Actualizar</v-btn>
                
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
        </template>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4">
        <template>
            <v-row justify="center">
              <v-col cols="12">
                <v-card 
                  ref="form"
                  elevation="8"
                  outlined
                >
                  <v-card-title>Sucursal</v-card-title>
                  <v-card-text>
         <!-- :rules="[() => !!name || 'This field is required']" -->
         <!-- required -->
         <!-- :error-messages="errorMessages" -->
                      <!-- :rules="[
                        () => !!address || 'This field is required',
                        () => !!address && address.length <= 25 || 'Address must be less than 25 characters',
                        addressCheck
                      ]" -->
                      <!-- counter="25" -->
                      <!-- :rules="[() => !!city || 'This field is required', addressCheck]" -->
                      <!-- :rules="[() => !!state || 'This field is required']" -->


                    <v-text-field
                      ref="name"
                      v-model="branches.company_id"
                      label="Compañia"
                      placeholder="Compañia"
                      readonly
                      ></v-text-field>
                      <v-text-field
                      ref="address"
                      v-model="branches.acronym"
                      label="Acronimo"
                      placeholder="iCHECK"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      ref="city"
                      v-model="branches.name"
                      label="Sucursal"
                      placeholder="Sucursal"
                      v-on:keyup.13="updateRowBranch(branches)"
                      required
                    ></v-text-field>
                    <v-text-field
                      ref="state"
                      v-model="branches.giro"
                      label="Giro"
                      placeholder="Tecnologia"
                      v-on:keyup.13="updateRowBranch(branches)"             
                      required
                    ></v-text-field>
                    <!-- :rules="[() => !!zip || 'This field is required']" -->
                    <!-- required -->
                    <v-text-field
                      ref="zip"
                      v-model="branches.rfc "
                      label="RFC"
                      placeholder="SACE8603SV4"
                      v-on:keyup.13="updateRowBranch(branches)"
                    ></v-text-field>
                    <!-- <v-autocomplete
                      ref="country"
                      v-model="country"
                      :rules="[() => !!country || 'This field is required']"
                      :items="countries"
                      label="Country"
                      placeholder="Select..."
                      required
                    ></v-autocomplete> -->
                  </v-card-text>
                  <br><br>

                  <span class="mx-4">
                    Ultima Actualizacion: {{ formatDate(branches.updated_at) }}
                  </span>

                  <v-divider class="mt-12"></v-divider>
                  <v-card-actions>
                    <v-btn text>
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-slide-x-reverse-transition>
                      <v-tooltip
                        v-if="formHasErrors"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="my-0"
                            v-bind="attrs"
                            @click="resetForm"
                            v-on="on"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Refresh form</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                    <v-btn v-on:click="updateRowBranch(branches)" class="green">Actualizar</v-btn>
                
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
        </template>
      </v-col>      
      <v-col cols="12" sm="12" md="6" lg="4">
        <!-- Descuentos de Precios -->
        <template>
            <v-row justify="center">
              <v-col cols="12">
                <v-card 
                  ref="form"
                  elevation="8"
                  outlined
                >
                  <v-card-title>Descuentos</v-card-title>
                  <v-card-subtitle>Se realizan en porcentajes a todos los articulos que se actualicen</v-card-subtitle>
                  <v-card-text>
                    <v-text-field
                        class="my-5 py-0"
                        v-model="setting.margin_wholesaler"    
                        v-on:keyup.13="updateRowBranch(setting)"             
                        label="Precio Mayorista"
                        placeholder="Escribe un Mayorista"
                        outlined
                    ></v-text-field>
                      <v-text-field
                                      class="my-5 py-0"
                                      v-model="setting.margin_retail"    
                                      v-on:keyup.13="updateRowBranch(setting)"             
                                      label="Precio Minorista"
                                      placeholder="Escribe un Minorista"
                                      outlined
                        ></v-text-field>
                        <v-text-field
                                      class="my-5 py-0"
                                      v-model="setting.margin_regular"    
                                      v-on:keyup.13="updateRowBranch(setting)"             
                                      label="Precio Regular"
                                      placeholder="Escribe un Regular"
                                      outlined
                        ></v-text-field>
                        <v-text-field
                                      class="my-5 py-0"
                                      v-model="setting.margin_public"    
                                      v-on:keyup.13="updateRowBranch(setting)"             
                                      label="Precio Publico"
                                      placeholder="Escribe un Publico"
                                      outlined
                        ></v-text-field>
                   
                  </v-card-text>
                  <span class="mx-4">
                    Ultima Actualizacion: {{ formatDate(setting.updated_at) }}                    
                  </span>

                  <v-divider class="mt-12"></v-divider>
                  <v-card-actions>
                    <v-btn text>
                      Cancel
                    </v-btn>
                      <v-btn v-on:click="editRowCurrency=false" class="deep-black">Cerrar</v-btn>
                    <v-spacer></v-spacer>
                    <v-slide-x-reverse-transition>
                      <v-tooltip
                        v-if="formHasErrors"
                        left
                      >
                        <template v-slot:activator="{ on, attrs }">

                          <v-btn
                            icon
                            class="my-0"
                            v-bind="attrs"
                            @click="resetForm"
                            v-on="on"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>Refresh form</span>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                    <v-btn v-on:click="updateRow(setting)" class="green">Actualizar</v-btn>
                
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
        </template>
        <!-- FIN Descuentos de Precios -->

      </v-col>
     </v-row>

     <div class="py-10">
     <h6>Suscursal</h6>
     <table class="table table-striped ">
       <thead class="thead-dark">
         <tr><th v-for="thead in theaderBranches" :key="thead.id">{{thead.text}}</th></tr>               
       </thead>
       <tr>
         <td>
           {{ branches.currency_id }}
          <v-btn v-on:click="editRowCurrency = branches.id" icon>
              <v-icon>edit</v-icon>
            </v-btn>
          <div v-if="editRowCurrency === branches.id">
            <v-autocomplete
                      class="py-4"
                      v-model="branches.currency_id"
                      :hint="`${currencies.id} - ${currencies.name} ${currencies.tipo_cambio}`"
                      :items="currencies"
                      item-text="tipo_cambio"
                      item-value="id"
                      label="Selecciona un Tipo de Cambio*"
                      outlined
            ></v-autocomplete>          
            <v-btn v-on:click="updateRowBranch(branches)" class="green">Actualizar</v-btn>
            <v-btn v-on:click="editRowCurrency=false" class="deep-black">Cerrar</v-btn>
          </div>
          </td>
       </tr>
     </table>
    </div>
  
   
   
    
        
   


  </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations, mapState } from "vuex";
//import FieldSearch from '@/components/FieldSearch.vue'
import baseApi from '@/services/baseApi';
import CurrencyForm from "@/components/CurrencyForm";
import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es";


export default {
  name: 'settings',
  computed:{
        ...mapState(['setting','branches','profile','currencies','currentUser'])
  },
  components: {
      //FieldSearch,
      CurrencyForm,
  },
  methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
      },  
      async getSetting(){       
        try{
          this.showLoading({titulo:"Accediendo información",color:"primary"})
          await this.$store.dispatch('loadSetting',this.attribute); 
          await this.$store.dispatch('loadBranchOne'); 
          await this.$store.dispatch('loadCurrencies',this.attribute);
          await this.$store.dispatch('loadProfile',this.attribute);
          

          // alert(this.setting.margin_retail);         
          this.attribute.sword="All";
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
      },     
      async AddCurrency(rowInfo){
        // alert("Agregar Evento");
        // alert (rowInfo.name);
        try{ 
          this.showLoading({titulo:"Almacenando información",color:"warning"})
          let tipo_cambio=rowInfo.tipo_cambio;
          let status=rowInfo.status;
          let abreviada=rowInfo.abreviada;
          // let fecha_inicio=rowInfo.fecha_inicio;
          // let fecha_fin=rowInfo.fecha_fin;
          // const { salesorder_id,tipo_cambio,fecha_inicio,cantidad } = this;        
          //let datos=await baseApi().post();          
          await baseApi().post(`/moneda`,JSON.stringify({ tipo_cambio, abreviada,status })).then((response)=>{
              if(!response.data.error){                            
                // this.$swal("Genial!","", "success");
                this.$swal("Genial!",response.data.message, "success");
              }else{
                // this.$swal("Error!",response.data.message, "error");
                this.$swal("Error!",JSON.stringify(response.data.validate), "error"); 
              }                                    
              // console.log("VENTA: "+response.data.message);
          });
        }catch(error){
          //console.log(error);
          if (error.message=='Request failed with status code 500') {
            this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
          }else if(error.message=='Request failed with status code 401'){
            this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
            router.push('/login');
          }else{
            this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
          } 
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        } 
      },   
      updateRow(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/settinguser/update/${row.id}`,JSON.stringify(row)).then((response)=>{
                if(!response.data.error){                            
                  // this.$swal("Genial!","", "success");
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
      updateRowBranch(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/sucursales/update/${row.id}`,JSON.stringify(row)).then((response)=>{
                if(!response.data.error){                            
                  // this.$swal("Genial!","", "success");
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
      updateRowProfile(row) {      
        try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/perfil/update/${row.id}`,JSON.stringify(row)).then((response)=>{
                if(!response.data.error){                            
                  // this.$swal("Genial!","", "success");
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
          }catch(error){
            console.log(error);
          }  finally{
            this.hiddenLoading()
            // this.$swal("Genial!", "Se actualizó la información", "success");
          } 
      },
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialog:false,
      // dialogNote:false,
      // sword:"",      
      editRow:null,
      editRow2:null,
      editRowCurrency:null,
      itemsStatus:[        
          'Proceso',
          'Contactado',
          'No Responde',
          'Finalizado'
      ],      
      theader: [
          { text: 'Acción', value: 'canasta_basica' },
          { text: 'Minorista', value: 'name' },
          { text: 'Mayorista', value: 'notes' },
          { text: 'Otro', value: 'created_at' },        
          { text: 'Publico', value: 'created_at' },        
          { text: 'Actualización', value: 'updated_at' },        
      ],
      theaderBranches: [
          // { text: 'Acción', value: 'canasta_basica' },
          // { text: 'Compañia', value: 'name' },
          // { text: 'Acronimo', value: 'name' },
          // { text: 'Sucursal', value: 'notes' },
          // { text: 'Giro', value: 'created_at' },        
          { text: 'Moneda', value: 'created_at' },        
          // { text: 'RFC', value: 'updated_at' },      
          // { text: 'Actualización', value: 'updated_at' },        
      ],
    }
  }, 
  created(){
    this.changeBreadcrumbs({
          page:"Setting",
          title:"Setting",
          subtitle:"primary",
          description:"Seccion de configuracion del perfil"
    });
    this.getSetting()    
  }
}
</script>