import axios from "axios";
// var r=JSON.parse(window.localStorage.currentUser);
//Cuando carga por primera vez no muestra nada la APP

let baseApi= axios.create({
    baseURL:'https://www.cloudbits.org.mx/fw/lumen/ApiCrm/'
});

const Api =function(){
    let token = localStorage.getItem('tokenBK');
    // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3LmNsb3VkYml0cy5vcmcubXhcL2Z3XC9sdW1lblwvY2hlY2tcL2xvZ2luIiwiaWF0IjoxNjM3ODc5MTExLCJleHAiOjE2Mzc4ODI3MTEsIm5iZiI6MTYzNzg3OTExMSwianRpIjoiQjc1ZkV3cFNTd1JsMmhCNiIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Qf_X2VUTZhk6LNaN1FLz12_wnFunuajclYq3V7dBf5k"
    // let token=JSON.parse(window.localStorage.token);
    if (token){
        // baseApi.defaults.headers.common['Authorization'] = 'Bearer ${token}'
        baseApi.defaults.headers.common = {
            // 'Accept':"application/json",
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': '*',
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+token
        };
    }

    return baseApi;
}

export default Api