<template>
  <v-container>
    <UserAuthForm :submitForm="registerUser" buttonText="Registro" hasName=true hasPass=true hasAuth=true />
  </v-container>  
</template>

<script>
import{ mapMutations } from "vuex";
import UserAuthForm from "@/components/UserAuthForm";
// import Cabecera from "@/components/Cabecera.vue";


export default {
   name: 'Registration',
    components: {
      UserAuthForm,
      // Cabecera      
    },    
    methods:{      
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),          
      async registerUser(registrationInfo){
        //console.log(registrationInfo);
        // let a=this.$refs.form.validate();
        // if(a==true){
          let user= await this.$store.dispatch('registerUser',registrationInfo);
          if (user.error){
             this.$swal("Uppppssss!", user.error, "error");
          }else{
             //console.log(user.success);
             this.$swal("Gracias por registrarte, Pronto tendrás noticias de nosotros ", "success");
          }        
        // }              
      },
      async getBreadcrumps(){
        this.changeBreadcrumbs({
          page:"Registro",
          title:"Registro de Usuario",
          subtitle:"primary",
          description:"Accederá hasta que se verifique el registro"
        });
        //let aFecha=dia.split(["-"])
        //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      }      
    },
    created(){
      this.getBreadcrumps(this.sword)    
    }    
}
</script>