var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.hasName)?_c('v-text-field',{attrs:{"label":"Name","rules":[
            _vm.required('name')
          ]},model:{value:(_vm.userInfo.name),callback:function ($$v) {_vm.$set(_vm.userInfo, "name", $$v)},expression:"userInfo.name"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"E-mail","rules":[
            _vm.required('email'),
            _vm.invalidEmail('email')
          ]},model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.hasPass)?_c('v-text-field',{attrs:{"label":"Password","color":"cyan darken","counter":7,"append-icon":_vm.showPassword ? 'visibility' : 'visibility_off',"type":_vm.showPassword ? 'text':'password',"rules":[
              _vm.required('password'),
              _vm.minLength('password',7)
            ],"loading":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}}):_vm._e()],1)],1),(_vm.hasRegister)?_c('v-checkbox',{attrs:{"label":"Usted es usuario del eCommerce?","rules":[_vm.accept('condiciones')]},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[(_vm.hasLogin)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"click":function($event){return _vm.submitForm(_vm.userInfo)}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[(_vm.hasLogin)?_c('v-btn',{attrs:{"to":"/forget"}},[_c('v-icon',[_vm._v("lock_open")]),_vm._v(" Olvide Password ")],1):_vm._e()],1)],1),(_vm.hasBoton)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_vm._v(" Reset Form ")]):_vm._e(),(_vm.hasBoton)?_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.resetValidation}},[_vm._v(" Reset Validation ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }