<template>
  <v-container>    
    <UserAuthForm :submitForm="forgetPassword" buttonText="Enviar" />    
  </v-container>  
</template>

<script>
import{ mapMutations } from "vuex";
import UserAuthForm from "@/components/UserAuthForm";


export default {
   name: 'login',
    components: {
      UserAuthForm,
      // Cabecera      
    },    
    methods:{      
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),          
      async forgetPassword(forgetInfo){
        //let a=this.$refs.form.validate();
        //alert(forgetInfo.email);
        // if(a==true){
          let email= await this.$store.dispatch('forgetUser',forgetInfo);
          if (email.error){
             this.$swal("Uppppssss!", email.error, "error");
          }else{
             this.$swal("Revisa tu bandeja de tu correo electrónico!", "Gracias por acceder " + email.email, "success");
             //this.$router.push({ path: '/' });
             //this.$router.push({name: 'Home'});
          }
        // }              
      },
      async getBreadcrumps(){
        this.changeBreadcrumbs({
          page:"Login",
          title:"Acceder",
          subtitle:"primary",
          description:""
        });
        //let aFecha=dia.split(["-"])
        //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      }      
    },
    created(){
      this.getBreadcrumps()    
    }    
}
</script>