<template>
  <v-container class="grey lighten-5">
      <v-row >
        <v-col cols="12" sm="12">
          <h5>Buscar Producto:</h5>
          <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="Escribe un sku, producto..."
                      v-on:keyup.13="getDataProduct(sword)"
                      autofocus
          ></v-text-field>
        </v-col>
        <v-col cols=12>
          <v-card>
              <v-list>
                <v-list-item>                             
                  <!-- <v-list-item-avatar> -->
                    <!-- <v-img
                                          
                      max-height="150"
                      max-width="150"
                                           
                      lazy-src="/img/avatar/avatar-1.png"
                      src="/img/avatar/avatar-1.png" 
                    ></v-img>                    
                  </v-list-item-avatar>      -->

                  <v-list-item-content>
                    <v-list-item-title>Encontramos</v-list-item-title>
                    <!-- <v-list-item-subtitle>Convertir en Requision, Salida o Compra</v-list-item-subtitle> -->
                  </v-list-item-content>      

                  <v-list-item-action>
                    {{priceslist.length}} artículo(s)
                  </v-list-item-action>
                </v-list-item>           
              </v-list>     

              <v-divider></v-divider> 
              <v-list> 
                <v-list-item v-for="(row,index) in priceslist " :key="index" @click="getPriceID(row)">
                  <v-list-item-avatar>
                    <v-img
                        :src="row.filename"
                        :lazy-src="row.filename"
                        :alt="`${row.description} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>      
                  <!-- <v-list-item-icon> -->
                    <!-- <v-icon> -->
                      <!-- <v-btn icon @click="UpdateStatus(row.id)">
                        <v-icon>check_circle</v-icon>
                      </v-btn> -->
                    <!-- </v-icon> -->
                  <!-- </v-list-item-icon> -->
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getTextShort(row.description) }}
                    </v-list-item-title>
                    <v-list-item-title >
                      <small>SKU: {{ row.sku }}</small>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text v-text="row.stock"></v-list-item-action-text>
                    <v-list-item-action-text>{{ row.price_publico | currency }}</v-list-item-action-text>
                      <!-- <v-icon
                        v-if="!active"
                        color="grey lighten-1"
                      >
                        mdi-star-outline
                      </v-icon> -->

                  </v-list-item-action>
                </v-list-item>
                
              </v-list>

              <v-divider></v-divider>     
             
              
            </v-card>
        </v-col>
      </v-row>                                                                         
  </v-container>

</template>


<script>
import{ mapMutations,mapState } from "vuex";
// import validations from '@/utils/validation';
// import{ DataManager,Query, WebApiAdaptor } from "@syncfusion/ej2-data";
// import baseApi from '@/services/baseApi';
// import router from '@/router';


export default {
  name: 'FieldSearch',  
  components: {
  },  
  computed:{
    // 'products',
    ...mapState(['priceslist']),
    // priceslist:{
    //   get(){
    //     //.toLowerCase() Para no aceptar Mayusculas
    //     return this.$store.state.filter.query;
    //   },
    //   set(val){
    //     this.$store.commit('SET_QUERY',val);
    //   }
    // },

  },
  props: {
    // product: Object,
    refresh: Boolean,
  },
  watch: { 
    // priceid: function(newVal, oldVal) { // watch it
    refresh: function() { // watch it
          // let products=JSON.stringify(this.car_products);
          // this.$store.dispatch('loadResetPricelist',products); 
          let products=[]
          this.$store.commit('SET_PRICESLIST',products);   
          this.sword=''
          // this.getDataProduct('')  
          // refresh.resetEmitted();
    }
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),   
    getTextShort(text){
      if (text.length > 35) {
       return text.slice(0, 35)+'...'
      }else return text
    },
    async getDataProduct(sword) {
      // alert(sword);
      // this.attribute.sword=sword
          try{        
            this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
            await this.$store.dispatch('loadPricelists',sword);            
          }catch(error){
            console.log(error);
          }finally{
            this.hiddenLoading()
          } 
    },
    getPriceID(valor){
      this.$emit('price-id', valor);
      // alert(valor)
    }
  },
  data(){
     return{
      attribute: {
        sword: 'All',
        status:'All',
        // date_start:'2019-01-01',
        // date_end:new Date().toISOString().substr(0,10),
        // pricelistid:'',
        limit: 27
      },
      sword:'',
     }
  },
  destroyed() {
    console.log("Componente eliminado");
  },
  mounted(){
    console.log("Montado Product Form");
    // if (this.hasValorID>0) {
    //   this.getDataProduct(this.hasValorID)
    // }
  },
  created(){
    // this.getDataProduct('')  
  }  
}
</script>

