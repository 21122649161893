
<template>
<div>
  <!-- Busqueda Return -->
  <v-row>
    <!-- <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="cars.length > 0"> -->
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <!-- <span class="right"><b></b> artículo(s) agregados <br></span> -->
      <!-- Productos en el Carrito -->
      <v-form
            ref="form"
            v-model="valid"
            lazy-validation
      >  
        <v-row>
          <v-col cols=12 sm=12 xs=12 md=12 lg=12>
            <h5>Artículos Agregados</h5>          
            <br><br>
            <!-- Tabla Salida -->
          <template>
                <!-- height="700px" -->
                <v-simple-table
                  fixed-header
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr><th class="text-center" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>               
                    </thead>
                    <tbody>
                      <br>
                      <tr v-for="(invoice_product, k) in invoice_products" :key="k">    
                          <td>
                              <v-btn icon @click="deleteRow(k, invoice_product)">
                                <v-icon color="red">delete_outline</v-icon>
                              </v-btn>                  
                          </td>    
                          <td>
                            {{ k+1 }}</td>          
                          <td>         
                            <v-text-field
                                  v-model="invoice_product.pricelist_id"
                                  label="SKU"
                                  :rules="[
                                    // required('sku'),
                                  ]"
                                  outlined
                                  readonly
                                  required
                              ></v-text-field>   
                                              
                          </td>
                          <td>                    
                              <v-text-field
                                  v-model="invoice_product.price"
                                  @change="calculateLineTotal(invoice_product)"
                                  class="form-control text-right"
                                  type="number"
                                  min="0" 
                                  step=".01"
                                  label="Precio"
                                  prefix="$"
                                  :rules="[
                                    required('precio'),
                                    onlyNumeric('precio')
                                  ]"
                                  outlined
                                  required
                              ></v-text-field>
                          </td>
                          <td>
                              <v-text-field
                                  v-model="invoice_product.cantidad"
                                  @change="calculateLineTotal(invoice_product)"                      
                                  class="form-control text-right"
                                  type="number"
                                  min="0" 
                                  step=".01"
                                  label="Cantidad"
                                  :rules="[
                                    onlyNumeric('cantidad')
                                  ]"
                                  outlined
                                  required
                              ></v-text-field>     
                              <!-- <span class="red" @click="disminuir(0)"><v-icon>remove</v-icon></span> -->
                              <!-- <span class="primary" @click="aumentar(0)"><v-icon>add</v-icon></span>              -->
                          </td>    
                          <td>
                              <v-text-field
                                  v-model="invoice_product.line_total"
                                  type="number"
                                  min="0" 
                                  step=".01"
                                  label="Total"
                                  :rules="[
                                    onlyNumeric('line_total')
                                  ]"
                                  outlined
                                  readonly
                                  required
                              ></v-text-field>                  
                          </td>

                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
          </template>
          </v-col>
          <v-col class="text-right">
            <div class="my-2">
              <b>SUBTOTAL: </b>
              {{invoice_subtotal | currency }}
              <br>
            </div>
            <div class="my-2">
              <b>IVA: </b>
              {{invoice_tax}}%
            </div>
            <div class="my-2">
              <b>TOTAL: </b>
              {{invoice_total | currency }}
            </div>
          </v-col>
        </v-row>
        <v-row>
              <v-col cols=12 sm=12 xs=12 md=12 lg=12 class="text-right">
                  <v-btn class="red" @click="deleteAll" >
                      <v-icon>delete_outline</v-icon>                    
                  </v-btn>  
                  <v-btn v-on:click="submit"  color="success"><v-icon>save</v-icon></v-btn>
              </v-col>
        </v-row>  
      </v-form>
      <!-- FIN Productos en el Carrito -->                                   
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

<!-- DIALOG NOTAS -->
  <div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  </div>
<!-- FIN DIALOG NOTAS -->
<!-- VISTA PRODUCTO DIALOG-->
  <template>                 
                      <v-dialog
                        v-model="dialogViewProduct"
                        persistent
                        max-width="800px"                 
                      >
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle Producto</span>
                              <v-btn
                                class="headline"
                                color="blue darken-1"
                                text
                                @click="dialogViewProduct = false"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-container v-if='this.dialogViewProduct==true'>
                                <ProductView :productid="productid"  />
                            </v-container>
                            <!-- :pricelist="priceslist -->
                            <!-- buttonText="Guardar"   -->
                          <v-card-actions>
                              <v-btn 
                              
                              color="red" 
                              @click="dialogViewProduct = false"
                              >
                              cerrar
                            </v-btn>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
                      </v-dialog>
  </template>                 
<!-- FIN VISTA PRODUCTO  DIALOG-->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
// import baseApi from '@/services/baseApi';
import icheckApi from '@/services/icheckApi';
import ProductView from "@/components/ProductView";
import validations from '@/utils/validation';


// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    // priceid:String
    priceid:{
      type:Object,
      required:true
    }
  },
  watch: { 
    // priceid: function(newVal, oldVal) { // watch it
    priceid: function(newVal) { // watch it
          // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
          this.addNewRow(newVal)
          // this.invoice_products[0] = this.priceid;
    }
  },
  computed:{
    //'products'
      ...mapState(['priceslist','currentUser'])
  },
  components: {    
    ProductView
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    deleteAll() {
      this.invoice_products.splice(0);
      this.calculateTotal();
      if (this.refreshrnd==false)this.refreshrnd=true
      else this.refreshrnd=false;
      this.$emit('refresh-id', this.refreshrnd);
      // this.invoice_products=this.invoice_products2;
      // this.priceid={};
    },
    deleteRow(index, invoice_product) {
            var idx = this.invoice_products.indexOf(invoice_product);
            // console.log(idx, index);
            if (idx > -1) {
                this.invoice_products.splice(idx, 1);
            }
            this.calculateTotal();
    },
    addNewRow(priceID) {
            // alert(this.priceid.id)
            let price=priceID.price_publico/1.16;
            this.invoice_products.push({
                // pricelist_id: '',
                pricelist_id: priceID.id,
                posicion: this.invoice_products.length+1,
                // price: priceID.price_publico,
                price: price.toFixed(2),
                cantidad: 1,
                discount: 0,
                line_total: 0
            });
            // console.log(JSON.stringify(this.invoice_products));

            //Se pone esto para que sepa que registro del arreglo se va a calcular
            var idx=0;
            if (this.invoice_products.length>0) idx=this.invoice_products.length-1;
            this.calculateLineTotal(this.invoice_products[idx])

    },
    calculateLineTotal(invoice_product) {
            // console.log(JSON.stringify(this.invoice_products));
            var total = parseFloat(invoice_product.price) * parseFloat(invoice_product.cantidad);
            // var total = parseFloat(stotal-(stotal*(invoice_product.discount/100)));
            // alert(total)

            if (!isNaN(total)) {
                invoice_product.line_total = total.toFixed(2);
            }
            this.calculateTotal();
    },
    calculateTotal() {
            var subtotal, total;
            subtotal = this.invoice_products.reduce(function (sum, product) {
                var lineTotal = parseFloat(product.line_total);
                if (!isNaN(lineTotal)) {
                    return sum + lineTotal;
                }
            }, 0);

            this.invoice_subtotal = subtotal.toFixed(2);

            total = (subtotal * (this.invoice_tax / 100)) + subtotal;
            total = parseFloat(total);
            if (!isNaN(total)) {
                this.invoice_total = total.toFixed(2);
            } else {
                this.invoice_total = '0.00'
            }
    },
    async getProduct(ProductID){
      // console.log('Producto ID:'+ProductID);
      this.productid=ProductID;
      // alert(this.productid);
      this.dialogViewProduct=true;      
    }, 
    submit: async function() {
      let a=this.$refs.form.validate();

      if(a==true){
        try{        
          this.showLoading({titulo:"Almacenando información",color:"warning"})  
          let branch_id=this.currentUser.branch_id;
          let customer_id=77;
          let title='Compra General'
          let iva=0;
          let notes='';
          let date_salesorder=new Date().toISOString().substr(0,10);
          let detailsalesorders=this.invoice_products;
          //alert (branch_id);                  
          // const { customer_id,  title, date_salesorder, iva, notes } = this;          
          icheckApi().post(`/notaremision/createAll`,JSON.stringify({ branch_id,customer_id,title,date_salesorder,iva,notes,detailsalesorders })).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              this.deleteAll();
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            //console.log("VENTAS: "+response.data.message);
          });           
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se agregó la información", "success");
        }   
      }         
    },   
    async getArticles(){   
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadPricelists',"All");
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },
    
   
    
  },
  destroyed() {
    console.log("Destruido CarItem");
  },
  mounted(){
    console.log("Montado CarItem");
    //Borramos el que crea por default
    this.deleteRow(0,this.invoice_products[0])
    // if (this.productid>0) {
      // this.getArticles()
    // }
  },
  data(){
    return{
      ...validations,
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      dialogViewProduct:false,
      refreshrnd:false,
      editRow:null,
      editRowProjectID:null,
      productid:0,
      employee_id:'',      
      notes:'',
      invoice_subtotal: 0,
      invoice_total: 0,
      invoice_tax: 16,
      invoice_products: [{
                pricelist_id: '',
                posicion: 1,
                price: '',
                cantidad: '',
                discount: 0,
                line_total: 0
      }],     
      itemsStatus: [
          'Proceso',
          'Cancelada',
          'Autorizada',
      ],   
      theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'SKU', value: 'customername' },
          { text: 'Precio', value: 'customername' },
          { text: 'Cantidad', value: 'total_quote ' },       
          { text: 'Total', value: 'total_quote ' },       
      ],
    }
  }
}
</script>