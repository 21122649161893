<template>
 <div id="botones">
  <!-- Botones up,down, sinc precio and stock -->
    <v-card
        flat
        class=""
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >

            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                      @click="upPricelist"
                    >
                          <v-icon >arrow_upward</v-icon>
                    </v-btn>
                  </template>
                  <span>Subir Precio</span>
                </v-tooltip>
            </template>    
            
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="gray"
                      v-bind="attrs"
                      v-on="on"
                      @click="downPricelist"
                    >
                          <v-icon >arrow_downward</v-icon>
                    </v-btn>
                  </template>
                  <span>Bajar Precio</span>
                </v-tooltip>
            </template>    

            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="primary darken-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="syncCoin"
                    >
                          <v-icon >credit_score</v-icon> Cashback
                    </v-btn>
                  </template>
                  <span>Actualizar Monedero</span>
                </v-tooltip>
            </template>
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      @click="syncStock"
                    >
                          <v-icon >refresh</v-icon> Stock
                    </v-btn>
                  </template>
                  <span>Actualizar Stock</span>
                </v-tooltip>
            </template> 
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>
  <!-- FIN Botones up,down, sinc precio and stock -->


  <!-- Woocommerce and MELI -->
    <v-card flat>
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">    
                      <v-btn                  
                        color="purple"
                        v-bind="attrs"
                        v-on="on"
                        @click="refreshWoocommerce('batch')"
                      >
                        <v-icon >store</v-icon>Woo
                      </v-btn>
                    </template>
                    <span>Subir Info a Woocommerce por batch</span>
                  </v-tooltip>
              </template>
              
              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">    
                      <v-btn                  
                        color="purple"
                        v-bind="attrs"
                        v-on="on"
                        @click="refreshWoocommerce"
                      >
                        <v-icon >store</v-icon>Woo
                      </v-btn>
                    </template>
                    <span>Subir Info a Woocommerce por lotes</span>
                  </v-tooltip>
              </template>

              <template>  
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">    
                      <v-btn                  
                        color="yellow"
                        v-bind="attrs"
                        v-on="on"
                        @click="refreshMeli"
                      >
                            <v-icon >local_mall</v-icon> Meli
                      </v-btn>
                    </template>
                    <span>Subir Info a Mercado Libre</span>
                  </v-tooltip>
              </template>

            </v-btn-toggle>
          </v-row>

          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >


            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="blue  darken-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshSyscom"
                    >
                          <v-icon >local_convenience_store</v-icon>SYSCOM
                    </v-btn>
                  </template>
                  <span>Bajar Info. de Syscom</span> 
                </v-tooltip>
            </template>    

            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="red  darken-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshCTOnline"
                    >
                          <v-icon >local_convenience_store</v-icon>CTOnline
                    </v-btn>
                  </template>
                  <span>Bajar Info. de CTOnline</span> 
                </v-tooltip>
            </template>   

            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="green  darken-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="refreshCVA"
                    >
                          <v-icon >local_convenience_store</v-icon>CVA
                    </v-btn>
                  </template>
                  <span>Bajar Info. de CVA</span> 
                </v-tooltip>
            </template>   

                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <v-btn                  
                      color="orange"
                      v-bind="attrs"
                      v-on="on"
                      @click="getFullPost"
                    >
                          <v-icon >rss_feed</v-icon> Blog
                    </v-btn>
                  </template>
                  <span>Bajar Post del Blog</span>
                </v-tooltip>
            </template> 
            </v-btn-toggle>
          </v-row>
         
      </v-card-text>
    </v-card>
  <!-- FIN Woocommerce and MELI -->

    
 </div>
</template>

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import apiCRM from '@/services/apiCRM';
import baseApi from '@/services/baseApi';

export default{
  name: "botones",
  created(){
    this.getBotones()    
  },
  computed:{
    ...mapState(['currentUser'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getBotones(){          
      try{        
        // this.r=JSON.parse(window.localStorage.currentUser)
        // console.log(this.r.id);
        // alert(this.attribute.limit);
        // alert(this.counter);
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        // this.$store.dispatch('loadVideos',this.attribute);        
        // this.src=this.src+videos[0].key;
        // this.counter=this.tips.length;         
        
        // alert (this.counter);
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },    
    async getBreadcrumps(sword){
        this.attribute.sword=sword;    
        try{        
          this.showLoading({titulo:"Accediendo información",color:"primary"})      
          this.$store.dispatch('loadSaldo',this.attribute);      
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
        } 
    },
    async getFullPost(){
      try{        
        //console.log(this.sword2);       
        let branchID=this.currentUser.branch_id;
        this.showLoading({titulo:"Actualizando información",color:"default"})
        const path=`/posts/full?branch_id=`+branchID+`&channel=`+this.channel;        
        let datos = await apiCRM().get(path);                    
        //console.log(datos.data.success.length);

        if(datos.data.success.length >0){
          this.rdesc=datos.data.success;
          this.rtitle="success";
        }else{
          this.rdesc="Sin resultados";
          this.rtitle="error";
        }
        }catch(error){
        //console.log(error);
        }  finally{
        this.hiddenLoading()
        this.$swal("Genial!", this.rdesc, this.rtitle);
        } 

    },
    async syncCoin(){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Actualizando Saldo Monedero",color:"warning"})          
          // let datos = await baseApi().get('/stock/sync');  
          // let branchID=this.currentUser.branch_id;                            
          await baseApi().get(`/saldo/synccoin`).then((response)=>{  
              // console.log(response.data.message); 
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("STOCK: "+response.data.message);            
          });   
        }catch(error){
          //console.log(error);
        }finally{
            this.hiddenLoading()
            //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
    },
    async syncStock(){
        try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Actualizando Stock",color:"warning"})          
          // let datos = await baseApi().get('/stock/sync');  
          // let branchID=this.currentUser.branch_id;
          await baseApi().get(`/stock/sync`).then((response)=>{  
              // console.log(response.data.message); 
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("STOCK: "+response.data.message);            
          });   
        }catch(error){
          //console.log(error);
        }finally{
            this.hiddenLoading()
            //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async refreshCVA(){
        try{                   
          this.showLoading({titulo:"Accediendo la información a CVA",color:"green"})   
          // await baseApi().get(`/ctconnect/product/1`).then((response)=>{        
          await baseApi().get(`/cva/updateprice`).then((response)=>{        
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
          });              
        }catch(error){
          //console.log(error);
        }finally{
          this.hiddenLoading()
          //this.$swal("Genial!", this.rdesc, this.rtitle);
        }
      },
      async refreshWoocommerce(valor){
        let path='';
        if (valor=='batch') {
          path='/woo/products/updatepricebatch'
        }else path='/woo/products/updateprice/5065'
        try{        
          //console.log(this.sword2);   
          // let branchID=this.currentUser.branch_id;    
          this.showLoading({titulo:"Actualizando precios de iCheck/Woocommerce",color:"purple"})
          
          await baseApi().get(path).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
     },
     async refreshSyscom(){
        try{        
          //console.log(this.sword2);   
          // let branchID=this.currentUser.branch_id;    
          this.showLoading({titulo:"Actualizando precios de Syscom/iCheck",color:"primary"})
          
          await baseApi().get(`/syscom/updateprice/5065`).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
     },
     async refreshCTOnline(){
        try{        
          //console.log(this.sword2);   
          // let branchID=this.currentUser.branch_id;    
          this.showLoading({titulo:"Actualizando precios de CTOnline/iCheck",color:"red"})
          
          // await baseApi().get(`/ctconnect/updateprice/5065`).then((response)=>{  
          await baseApi().get(`/ctconnect/updateprice/5065`).then((response)=>{  
            if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
          });          
        }catch(error){
          //console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", this.rdesc, this.rtitle);
        } 
     },
     async downPricelist(){
      try{        
        //console.log(this.sword2);       
        // let branchID=this.currentUser.branch_id;
        this.showLoading({titulo:"Bajando los precios en iCheck",color:"default"})
        await baseApi().get(`/precios/downprice/2727`).then((response)=>{              
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                          
        });                    
      }catch(error){
      //console.log(error);
      }  finally{
      this.hiddenLoading()
      // this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async upPricelist(){
      try{        
      //console.log(this.sword2);    
        // let branchID=this.currentUser.branch_id;   
        this.showLoading({titulo:"Subiendo precios en iCheck",color:"default"})
        await baseApi().get(`/precios/upprice/2727`).then((response)=>{              
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                          
        });                    
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    },
    async refreshMeli(){
      try{
        // let branchID=this.currentUser.branch_id;
        this.showLoading({titulo:"Actualizando precios de Mercado Libre/iCheck",color:"yellow"})
        await baseApi().get(`/mlibre/updateprice/5065`).then((response)=>{
          if(!response.data.error){        
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                    
              // console.log("VENTA: "+response.data.message);
        });
      }catch(error){
        //console.log(error);
      }finally{
        this.hiddenLoading();
        // this.$swal("Genial!", this.rdesc, this.rtitle);
      } 
    }    
  },
  data(){
      return{
        attribute: {sword: '',limit: 1},
        channel:"@iOnOffMX",
        toggle_exclusive:'',
        revents:null,
        counter:null
      }
  }  
}
</script>