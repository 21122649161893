<template>
  <v-app id="inspire">
    <v-app-bar
      class="px-0"
      density="compact"
      flat
    >
      <v-avatar
        class="hidden-md-and-up"
        color="grey-darken-1"
        size="32"
      >x</v-avatar>

      <v-spacer></v-spacer>
      

      <v-tabs
        color="grey-darken-2"
        centered
      >
        <v-tab
          v-for="link in links"
          :key="link"
          :text="link"
        >
        {{ link }}
        </v-tab>
        <v-tab>
           <!-- Eventos -->
            <Notify /> 
            <!-- FIN Eventos -->
        </v-tab>

      </v-tabs>
      <v-spacer></v-spacer>

      <v-avatar
        class="hidden-sm-and-down"
        color="grey-darken-1"
        size="32"
      >x</v-avatar>
    </v-app-bar>

    <v-main class="bg-grey-lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" >
            <v-sheet
              min-height="168"
              rounded="lg"
            >
            <!-- Buscar Productos -->            
              <FieldSearch :refresh="refresh" @price-id="getPriceID($event)" />
            <!-- FIN Buscar Productos -->
            </v-sheet>
          </v-col>

          <v-col cols="12" md="8" >
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
            <!--  Car -->            
            <CarItemPOS  :priceid="priceid" @refresh-id="refreshPage($event)" />
            <!-- FIN Car -->            
            </v-sheet>
          </v-col>          
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
// import {mapState} from "vuex";
// import{ mapMutations } from "vuex" ;
import{ mapMutations,mapState } from "vuex";
//import SearchProducts from "./components/SearchProducts.vue";
// import ToolTop from "@/components/ToolTop";
// import Breadcrumbs from '@/components/Breadcrumbs';
// import Footer from "@/components/Footer";
import icheckApi from '@/services/icheckApi';
import FieldSearch from '@/components/FieldSearch.vue'
import CarItemPOS from "@/components/CarItemPOS";
import Notify from '@/components/Notify'


export default{
    name:'POSLayout',
    computed:{
        ...mapState(['currentUser'])
    },
    components:{    
      FieldSearch,
      CarItemPOS,
      Notify
        // Productos,
        // ToolTop,
        // Breadcrumbs,
        // Footer,
        //SearchProducts
    },
    methods:{
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      refreshPage(event){
        this.refresh=event
        // alert(this.refresh)
      },
      getPriceID(event){
        // console.log(event)
        // alert(event)
        // this.priceid=event.toString()
        this.priceid=event
      },
      async getCar(){
          try{            
            this.showLoading({titulo:"Accediendo información",color:"primary"})
            // let branch_id=this.currentUser.branch_id;
            // console.log('Car icheckApi');
            let datos= await icheckApi().get(`/carrito/getall`);
            // let datos= await baseApi().get(`/carrito/getall`);
            // let datos= await baseApi().get(`/detalleventa/`+this.$route.query.salesorderid+`?branch_id=`+branch_id);
            // console.log(datos.data.length);
            if(datos.data.length >0){
              this.rows=datos.data;            
              for (var i = 0; i < this.rows.length; i+=1) {   
                // console.log(this.rows[i]);
                // this.folio=this.rows[0].purchase_id;            
                // this.razon_social=this.rows[0].razon_social; 
                // this.date_purchase=this.rows[0].date_buy; 
                this.subtotal=(this.subtotal + (this.rows[i].price*this.rows[i].qty));  //Se multiplica con uno para que lo convierta en numero            

                let total_line=this.calculateLineTotal(this.rows[i]);
                // console.log(total_line);
                //this.quote_subtotal=(this.quote_subtotal + (total_line-(total_line*(this.rows[i].discount/100))));  //Se multiplica con uno para que lo convierta en numero                        
                this.car_subtotal=this.car_subtotal + total_line;                      

                let purchaseTotal=this.car_subtotal;            
                
                if (this.rows[0].discount!=0) this.desc=(this.car_subtotal*(this.rows[0].discount/100)); 
                else this.desc=0;

                this.car_subtotal2=purchaseTotal-this.desc;
                
                if (this.rows[0].iva!=0) this.iva=(purchaseTotal*0.16); 
                else this.iva=0;            

                this.totalcost=this.car_subtotal2 + this.iva;            
              }                     
              //this.desserts=datos.data          
            }else{
              this.rows=[];
            }

          }catch(error){
            //console.log(error);
          }  finally{
            this.hiddenLoading()
          } 
      }, 
    },
    data: () => ({     
      enabled: false,      
      dragging: false,
      dialog: false,
      toggle_exclusive:'',
      draggingInfo:'',
      sword:"",
      productrow:{},
      priceid:'',
      refresh:false,
      sfecha:new Date().toISOString().substr(0,10),
      rows: [],
      subtotal:0,
      car_subtotal:0,
      editRow:null,
      links: [
        'Inicio',
        'Ventas',
        'Perfil',
      ],
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'subtotal_quote' },
        // {
        //   text: 'ID',
        //   align: 'start',
        //   sortable: false,
        //   value: 'id',
        // },
        
        { text: 'Imagen', value: 'filename' },        
        { text: 'Producto', value: 'subtotal_quote' },
        { text: 'Precio de Lista', value: 'precio_other' },
        { text: 'Descuento', value: 'discount' },                
        { text: 'Precio Unidad', value: 'precio_other' },
        { text: 'Cantidad', value: 'cantidad' },                
        { text: 'Importe', value: 'total_quote' }, 
        { text: 'Fecha Registro', value: 'created_at' },
        { text: 'Fecha Actualización', value: 'updated_at' },
      ],
      acciones: [
        { title: 'Download PDF',icon:"save",metodo:"downloadPDF" }
      ],
    }),
    created(){
      // this.getCar()     
    }
}
</script>
<!-- <script setup>
  const links = [
    'Dashboard',
    'Messages',
    'Profile',
    'Updates',
  ]
</script>  -->

