var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"EventForm"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Tipo de Cambio*","rules":[
                    _vm.required('tipo de cambio')
                  ]},model:{value:(_vm.rowInfo.tipo_cambio),callback:function ($$v) {_vm.$set(_vm.rowInfo, "tipo_cambio", $$v)},expression:"rowInfo.tipo_cambio"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-combobox',{attrs:{"items":_vm.itemsSiglas,"label":"Seleccione una sigla*","rules":[
                    _vm.required('tipo de cambio')
                  ]},model:{value:(_vm.rowInfo.abreviada),callback:function ($$v) {_vm.$set(_vm.rowInfo, "abreviada", $$v)},expression:"rowInfo.abreviada"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-combobox',{attrs:{"items":_vm.itemsStatus,"label":"Seleccione un status*","rules":[
                    _vm.required('tipo de cambio')
                  ]},model:{value:(_vm.rowInfo.status),callback:function ($$v) {_vm.$set(_vm.rowInfo, "status", $$v)},expression:"rowInfo.status"}})],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.submitForm(_vm.rowInfo)}}},[_c('v-icon',[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error"},on:{"click":_vm.reset}},[_c('v-icon',[_vm._v("cleaning_services")]),_vm._v(" Reset Form ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }