<template>
  <div class="users">   
    <template>
      <v-container class="grey lighten-5">
          <v-row  :class="sm-6">
            <v-col>
              <h5>Buscar:</h5>
              <v-text-field
                      v-model="sword"
                      hide-details
                      prepend-icon="mdi-magnify"
                      single-line        
                      placeholder="buscar..."
                      v-on:keyup.13="getBreadcrumps(sword)"
                      autofocus
              ></v-text-field>
            </v-col>
          </v-row>                                                                            
      </v-container>
    </template> 

    <v-card
        flat
        class="py-12"
      >
        <v-card-text >
          <v-row class="text-right" justify="center" >        
            <v-btn-toggle
              v-model="toggle_exclusive"
              mandatory
            >
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">    
                    <router-link to="/producto-add">
                    <v-btn                  
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </router-link>
                  </template>
                  <span>Agregar Nuevo Usuario</span>
                </v-tooltip>
            </template>                               
                      
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">              
                    <v-btn
                      @click="$router.back()" 
                      color="primary darken-2"               
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </template>
                  <span>Regresar a la Página Anterior</span>
                </v-tooltip>
            </template>
              
              
            </v-btn-toggle>
          </v-row>
      </v-card-text>
    </v-card>

   

      <!-- <h5>Agregar Nuevo {{$route.params.id}}</h5> -->
      <table class="table table-striped">
        <thead class="thead-dark">
          <th v-for="thead in theader" :key="thead.id">{{thead.text}}</th>
        </thead>
        <tr v-for="(row,index) of users" :key="row.id">                 
          <td>
            <!-- 
            <button v-on:click="deleterow(row.id, i)">x</button>
            -->
            <v-btn v-on:click="editRow = row.id" icon>
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn v-on:click="editPass = row.id" icon>
              <v-icon>password</v-icon>
            </v-btn>
            <v-btn v-on:click="loginUser(row)" >
              Login
            </v-btn>
          </td>
          <td>{{index+1}}</td>
          <td>{{row.id}}</td>
          <!--
            <td>
            <img src={{row.filename}} alt="">
          </td>
          -->
          <td>
            <div v-if="editRow === row.id">
              <v-text-field
                      v-model="row.name"
                      v-on:keyup.13="updateRow(row)"                    
                      label="Descripción"
              />              
              <v-text-field
                      v-model="row.telegram_id"
                      v-on:keyup.13="updateRow(row)"                      
                      label="telegram ID"
              />
              <v-text-field
                      v-model="row.email"
                      v-on:keyup.13="updateRow(row)"                      
                      label="Email"
              />
              <v-text-field
                      v-model="row.avatar"
                      v-on:keyup.13="updateRow(row)"                      
                      label="Avatar"
              />           
              <v-text-field
                      v-model="row.avatar_original"
                      v-on:keyup.13="updateRow(row)"                      
                      label="Avatar Original"
              />           
                               
              <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
              <v-btn v-on:click="editRow=false" class="deep-black">Cerrar</v-btn>

            </div>
            <div v-else-if="editPass === row.id">
              <v-text-field
                      v-model="row.password2"
                      v-on:keyup.13="updatePass(row)"                      
                      label="Cambiar Password"
              />           
              <v-btn v-on:click="updatePass(row)" class="green">Actualizar</v-btn>
              <v-btn v-on:click="editPass=false" class="deep-black">Cerrar</v-btn>

            </div>
            <div v-else>
              <div v-if='row.avatar != null'>
                <v-img
                  v-bind:lazy-src="row.avatar"
                  max-height="150"
                  max-width="150"
                  v-bind:src="row.avatar"
                ></v-img>
              </div>      
               {{row.name}}                            
            </div>
          </td>          
          <td>{{row.company_name}}</td>          
          <td>{{row.branch_name}}</td>          
          <td>{{row.email}}</td>          
          <td>{{row.telegram_id}}</td>
          <td>{{row.created_at}}</td>    
          <td>{{row.updated_at}}</td>    
        </tr>
        </table>          
        
        
    <!--    
    </v-flex>
  </v-layout>
</v-container>
 -->


  </div>
</template>

<script>
// import Api from "@/services/api";
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';

export default{
  name: 'users', 
  computed:{
    ...mapState(['users'])
    //el mapState es lo mismo que la instrucción de abajo
    //users(){return this.$store.state.users}
  },
  mounted(){
    //this.$store.dispatch('loadUsers',this.sword);
    //this.loadUsers(sword);
    // Api().get(`/productos/getall`).then((response)=>{
    //   this.rows=response.data;
    // });
  },     
  methods:{
    ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),  

    loginUser(user){
      //Se debe enviar el password y con solo clic no manda el password
      //console.log(user);
      // alert(user.email);
      // alert(user.password);
      this.$store.dispatch('sloginUser',user);
      this.$swal("Bienvenido!", "Gracias por acceder " + user.name, "success");      
    },    
    async getBreadcrumps(sword){   
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];   
      try{                
        this.showLoading({titulo:"Accediendo información",color:"primary"})        
        this.$store.dispatch('loadUsers',sword);
        //SIN $STORE
        // Api().get(`/productos/getall?sword=${sword}`).then((response)=>{
        //   this.rows=response.data;
        // });    

        //SIN NADA          
        // const baseURL=`https://www.cloudbits.org.mx/fw/lumen/check/clientes/getall?branch_id=1&limit=27&word=${sword}`;
        // const config = {
        //   method: 'get', //you can set what request you want to be
        //   url:baseURL,
        //   timeout: 10000,
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json",
        //     "Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        //   }
        // }
        
        // let datos=await axios(config)
        // console.log(datos);
        
        // if(datos.data.length >0){
        //   this.rows=datos.data          
        //   this.desserts=datos.data          
        // }else{
        //   this.rows="Sin resultados";
        // }
      } catch(error){
        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
      
      //let aFecha=dia.split(["-"])
      //let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];        
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})                  
          let datos=baseApi().post(`/usuario/update/${row.id}`,JSON.stringify(row));
          console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    updatePass(row) {      
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"})        
          // let datos=baseApi().post(`/usuario/changePass/${row.id}`,{ password2: row.password2 });   
          baseApi().post(`/usuario/changePass/${row.id}`,{ password2: row.password2 }).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            console.log("Usuario: "+response.data.message);
          });          
        }catch(error){
          // console.log("Usuario: "+datos.data.message);
          console.log("Error Usuario"+error);
          this.$swal("Error!", "Revise su información", "error");
        }  finally{
          this.hiddenLoading();
          // this.$swal("Genial!", "Se actualizó el password", "success");
        } 
    },
  },  
  created(){
    this.changeBreadcrumbs({
        page:"Usuarios",
        title:"Lista de Usuarios",
        subtitle:"primary",
        description:"En esta lista encontrará todos los usuarios dados de alta en el sistema desde el principio de los tiempos"
    });      
    this.getBreadcrumps(this.sword)    
  },  
  data(){
    return{
      sword:"",
      sfecha:new Date().toISOString().substr(0,10),
      editRow: null,
      editPass: null,
      theader: [
        {text:"Acción"},
        {text:"#"},
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'sku' },
        { text: 'Compañía', value: 'company' },
        { text: 'Sucursal', value: 'branch' },
        { text: 'Email', value: 'type_article' },        
        { text: 'Telegram', value: 'marca' },
        { text: 'Fecha Registro', value: 'metrica' },
        { text: 'Fecha Update', value: 'metrica' },
      ],
      //desserts: null
    }
  },
}

</script>
