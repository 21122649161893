<template>
    <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-row>        
            <v-col cols="12" xs="12" sm="2" md="2" lg="4" xl="4"></v-col>
            <v-col cols="12" xs="12" sm="8" md="8" lg="4" xl="4" class="login">                
                    <v-img
                        src="https://www.cloudbits.org.mx/img/logo-icheck.png"
                        lazy-src="https://www.cloudbits.org.mx/img/logo-icheck.png"
                        aspect-ratio="1"
                        class="loginimg"
                    ></v-img>                
                    <slot></slot>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2" lg="4" xl="4">
            </v-col>
            </v-row>                         
        </v-col>        
    </v-row>
</template>

<script>
export default{
    name:'loginLayout'
}
</script>

<style>
.logback{
	background: url("https://www.cloudbits.org.mx/img/back-icheck.jpg")no-repeat center center fixed;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login{
    margin-top:10%;
	padding:20px;
    /* left: 35%; */
	/* position: absolute; */
    /* text-align: center; */
	/* justify-content: center; */    

	border-radius: 40px;	
	background: rgba(103,146,206,0.8);
	background: -moz-linear-gradient(left, rgba(103,146,206,0.8) 0%, rgba(79,64,137,0.8) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(103,146,206,0.8)), color-stop(100%, rgba(79,64,137,0.8)));
	background: -webkit-linear-gradient(left, rgba(103,146,206,0.8) 0%, rgba(79,64,137,0.8) 100%);
	background: -o-linear-gradient(left, rgba(103,146,206,0.8) 0%, rgba(79,64,137,0.8) 100%);
	background: -ms-linear-gradient(left, rgba(103,146,206,0.8) 0%, rgba(79,64,137,0.8) 100%);
	background: linear-gradient(to right, rgba(103,146,206,0.8) 0%, rgba(79,64,137,0.8) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6792ce', endColorstr='#4f4089', GradientType=1 );
}

.logback .v-input{
	color:white;
	text-align: left;
	margin:22px 0 0 0;
}


.loginimg{
    text-align: center;
    align-items:center;
	margin: 5px auto;
    display: block;
    width: 95px;
}

.v-application .error--text {
    color: #f7f567 !important;
    caret-color: #f7f567 !important;
}
</style>
