<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChart',
  computed:{
        ...mapState(['saldo'])
  },
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
  },
  async mounted () {    
    this.loaded = false    
    try{                
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        let month2=new Date();        
        var year=month2.getFullYear(); //Año actual
        for (var i = 1; i <= month2.getMonth()+1; i+=1) {
          var month=i;        
          this.attribute.date_start=new Date(year, month - 1, 1) //Fecha del primer día del mes
          this.chartData.labels.push(this.attribute.date_start.toLocaleString('es-MX', {month: 'long'}));
          this.attribute.date_start= `${year}-${month}-${this.attribute.date_start.getDate()}` //Formateamos la fecha
          this.attribute.date_end = new Date(year, month, 0) //Fecha del último día del mes          
          this.attribute.date_end= `${year}-${month}-${this.attribute.date_end.getDate()}` //Formateamos la fecha
          // alert(this.attribute.date_start);
          // alert(this.attribute.date_end);
          await this.$store.dispatch('loadSaldo',this.attribute);               
          this.chartData.datasets[0].data.push(this.saldo[0].saldo);          
        }                
        this.loaded = true
    }catch(error){
       console.log(error);
    }  finally{
        this.hiddenLoading()
    }         
  },
  data() {
    return {
      attribute: {sword: '',limit: 27, date_start:'',date_end:''},
      loaded: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Ventas de Últimos 6 meses',
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>