<template>
  <POSLayout>
  </POSLayout>   
</template>


<script>
import POSLayout from "@/layouts/POSLayout"
// import{ mapMutations } from "vuex";
// import{ mapMutations,mapState } from "vuex";
// import UserAuthForm from "@/components/UserAuthForm";
// import auth from "@/auth";

export default {
   name: 'pos',
    components: {
      POSLayout,
      // UserAuthForm,
      // Cabecera      
    },    
    methods:{      
      // ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
    },
    created(){ 
    }    
}
</script>