<!-- 
-- Tipo de cambio Chileno 
-- Se multiplica por 0.024 para convertirlo a pesos MX
-->
<template>
 <div id="notify">
   <!-- Solo funciona entre semana -->
    <v-btn 
            plain 
            @click="setCurrency(valor)"      
        >
          <span>Dolar: {{valor | currency }}</span>
    </v-btn>   
   <!-- <span>Dolar: {{valor}}</span> -->
 </div>
</template>

<script>
import axios from "axios";
import {mapMutations} from "vuex";
import baseApi from '@/services/baseApi';


export default{
  name: "indicador",    
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async setCurrency(valor){
      let tipo_cambio=valor.toFixed(2);
      try{        
          this.showLoading({titulo:"Accediendo información",color:"warning"});
          
          if(confirm("Desea agregar este tipo de cambio: "+ tipo_cambio+"?")){
            // alert("Estoy en OK")
            let status='Venta';
            let abreviada='USD';
            await baseApi().post(`/moneda`,JSON.stringify({ tipo_cambio, abreviada,status })).then((response)=>{
              if(!response.data.error){                            
                this.$swal("Genial!",response.data.message, "success");
              }else{
                this.$swal("Error!",JSON.stringify(response.data.validate), "error"); 
              }                                    
            });
          }else{
            // alert("NO acepto :(")
          }          
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    }, 
    async getDolarExchangeRate(){
      let ERApi='7b58ff0dd21d3f6a035f44e3';
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        let datos =await axios.get(`https://v6.exchangerate-api.com/v6/${ERApi}/latest/USD`)      
        //console.log(datos);
        //console.log(datos.data.conversion_rates.MXN);
        // console.log(datos.data.conversion_rates.length);
        if(datos.data.conversion_rates.MXN > 0){
          this.valor=datos.data.conversion_rates.MXN;
        }else{
          this.valor="Sin resultados";
        }
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async getDolarChile(dia){
      let aFecha=dia.split(["-"])
      let ddmmyy=aFecha[2]+"-"+aFecha[1]+"-"+aFecha[0];
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        let datos =await axios.get(`https://mindicador.cl/api/dolar/${ddmmyy}`)      
        //console.log(datos);
        if(datos.data.serie.length >0){
          this.valor=datos.data.serie[0].valor*0.02454;
        }else{
          this.valor="Sin resultados";
        }
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    },
    async getDolarBmx(dia){
      //No found bloqueado por CORS localhost
      //alert(dia)
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        const baseURL=`https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/${dia}/${dia}`;        
        const config = {
            method: 'GET', //you can set what request you want to be
            url:baseURL,
            // timeout: 10000,
            mode: 'no-cors',
            headers: {
              // 'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json;charset=UTF-8',
              //"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
              //'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
              //'Content-Type':'application/json;charset=utf-8',
              //'Content-Type':'application/x-www-form-urlencoded',
              //'Access-Control-Allow-Headers': '*',
              "Bmx-Token":'0bc9ca97608768dd1efdee029311ef23efe268ac1ad12eba0de2b443e42c4663'
            }
        }

        //const cors = require("cors");
        //app.options("*", cors({ origin: 'http://127.0.0.1:8080/', optionsSuccessStatus: 200 }));
        //app.use(cors({ origin: "http://127.0.0.1:8080/", optionsSuccessStatus: 200 }));
          
        let datos=await axios(config)
        console.log("BMX"+datos);        
        //console.log(datos);
        if(datos.data.serie.length >0){
          this.valor=datos.data.serie[0].valor*0.028;
        }else{
          this.valor="Sin resultados";
        }
      }catch(error){
      //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
    }
  },
  data(){
    return{
      sfecha:new Date().toISOString().substr(0,10),
      fecha:"",
      minimo:"1984",
      maximo: new Date().toISOString().substr(0,10),
      valor:null
    }
  },
  created(){
    this.getDolarExchangeRate()    
    //this.getDolarChile(this.sfecha)    
    //this.getDolarBmx(this.sfecha)    
  }
}
</script>