<template>
  <div class="home">  
    <v-row >
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-right">        
          <Indicador />        
      </v-col>    
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" >
        <v-row >
          <v-col cols="12" sm="12" md="6" lg="8" xl="8" >
            <MenuDash />
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" xl="4" >
            <Tips />   
          </v-col>
        </v-row>                
      </v-col>
    </v-row>        
    <!-- <v-spacer></v-spacer>   -->
    <v-row class="text-right">
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" >  
        <LineChart />
        <!-- <BarChart /> -->
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" >  
        <Saldo />        
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" >  
        <Kpis />
      </v-col>
    </v-row>    
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" >           
        <!-- <Videos></Videos>   -->        
      </v-col>      
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <Botones />        
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" >          
        <!-- <SearchBrews /> -->
      </v-col>                                
    </v-row>    
    
  </div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// @ is an alias to /src
// import axios from "axios";
import Indicador from '@/components/Indicador'
import Tips from "@/components/Tips.vue";
// import Videos from "@/components/Videos.vue";
import Saldo from "@/components/Saldo.vue";
import MenuDash from "@/components/MenuDash.vue";
import Botones from "@/components/Botones.vue";
import Kpis from "@/components/Kpis.vue";
// import BarChart from '@/components/ChartBar.vue'
import LineChart from '@/components/ChartLine.vue'
// import SearchProducts from '@/components/SearchProducts'
//import SearchBrews from "@/components/SearchBrews.vue";
import router from '@/router'


export default {
  name: 'home',  
  computed:{
    ...mapState(['currentUser'])
  },  
  components: {
    // SearchBrews,
    Indicador,    
    Tips,
    // Videos,
    MenuDash,
    Saldo,
    Botones,
    Kpis,
    // BarChart,
    LineChart 
  },  
  methods:{
   ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),   
   async getBreadcrumps(sword){
      if(!this.currentUser.name){
            this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
            router.push('/login'); 
      }
      // alert(this.token)
      this.attribute.sword=sword;    
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})              
      }catch(error){

        //console.log(error);
      }  finally{
        this.hiddenLoading()
      } 
      
   }   
 },
 created(){
    //Para que cargue de inmediato el CurrentUser
    //this.$store.dispatch('loadCurrentUser','All');      
    // let branch_id=this.currentUser.branch_id;
    this.changeBreadcrumbs({
      title:"Dashboard",
      subtitle:"primary",
      description:"¡Te damos la Bienvenida "+this.currentUser.name+"!"
    });   

    this.getBreadcrumps(this.sword)    
  },
  data(){
    return{
      attribute: {sword: '',limit: 27},
      sword:"",
      sword2:"@iOnOffMX",      
      rdesc:"",
      rtitle:"",
      items: [{
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        }
      ],
    }
  }
}
</script>
