<template>
 <v-row id="tips">   
   <v-col cols="12" xs="12" sm="12" md="12" lg="12">
    <v-carousel hide-delimiters>
      <v-carousel-item
        v-for="(item,i) in tips"
        :key="i"
        :src="item.src"        
        reverse-transition="fade"
        transition="fade"
      >
        <v-card
            class="mx-auto"
            color="#26c6da"
            dark
            max-width="100%"            
          >
            <v-card-title>
              <v-icon
                large
                left
              >
                mdi-twitter
              </v-icon>
              <span class="title font-weight-light">Twitter</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
              "
              {{item.consejo}}
              "
              <br>
              <small>{{item.name}}</small>
            </v-card-text>
            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{item.tags}}</v-list-item-title>
                </v-list-item-content>
                <v-row
                  align="center"
                  justify="end"
                >
                  <v-icon class="mr-1">mdi-heart</v-icon>
                  <span class="subheading mr-2">256</span>
                  <span class="mr-1">·</span>
                  <v-icon class="mr-1">mdi-share-variant</v-icon>
                  <span class="subheading">45</span>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
      </v-carousel-item>
    </v-carousel>
          
      </v-col>   
 </v-row>
</template>

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
// import router from '@/router'
// import { RefTagger } from 'react-reftagger';



export default{
  name: "tips",
  created(){
    this.getTips()    
  },
  computed:{
        ...mapState(['tips'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getTips(){   
      //router.push('/login');       
      try{                
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadTips',this.attribute);
      }catch(error){
        console.log('Tip '+error);
      }  finally{
        this.hiddenLoading()
      } 
    },    
  },
  data(){
      return{
        attribute: {sword: '',limit: 1},
        revents:null,
      }
  }
}


  //logos Biblia
  // var refTagger = {
  //   settings: {
  //     bibleVersion: "RVR60",   
  //     // 'ESV'   
  //     addLogosLink: false,
  //     appendIconToLibLinks: false,
  //     roundCorners: true,     
  //     customStyle : {
  //       heading: {
  //         backgroundColor : "#4a86e8",
  //         color : "#ffffff"
  //       },
  //       body   : {
  //         color : "#666666",
  //         moreLink : {
  //           color: "#0000ff"
  //         }
  //       }
  //     }
  //   }
  // };


  // (function(d, t) {
  //   var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
  //   g.src = '//api.reftagger.com/v2/reftagger.es.js';
  //   s.parentNode.insertBefore(g, s);
  // }(document, 'script'));

 

// (function(d, t) {
//   var n=d.querySelector('[nonce]');
//   refTagger.settings.nonce = n && (n.nonce||n.getAttribute('nonce'));
//   var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
//   g.src = 'https://api.reftagger.com/v2/RefTagger.js';
//   g.nonce = refTagger.settings.nonce;
//   s.parentNode.insertBefore(g, s);
// }(document, 'script'));

</script>
