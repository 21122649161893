<template>  
    <loginLayout>
        <UserAuthForm :submitForm="loginUser" buttonText="Acceder" hasLogin=true hasPass=true hasAuth=true />
    </loginLayout>    
</template>

<script>
import loginLayout from "@/layouts/loginLayout"
import{ mapMutations } from "vuex";
// import{ mapMutations,mapState } from "vuex";
import UserAuthForm from "@/components/UserAuthForm";
// import auth from "@/auth";

export default {
   name: 'login',
    components: {
      loginLayout,
      UserAuthForm,
      // Cabecera      
    },    
    methods:{      
      ...mapMutations(["showLoading","hiddenLoading","changeBreadcrumbs"]),
      async loginUser(loginInfo){        
        // alert (loginInfo.email);

        //Otra manera de logear
        // let response= auth.LogIn(loginInfo);        
        // //console.log("Error en vista: "+response.message);
        // if (response.error==true){
        //    this.$swal("Uppppssss!", response.message, "error");
        // }else{
        //    this.$swal("Bienvenido!", response.message, "success");
        // }
        //FIN Otra manera de logear
        
        //let a=this.$refs.form.validate();
        //alert(a);
        // if(a==true){
        let user= await this.$store.dispatch('loginUser',loginInfo);
        // console.log('Mas '+user.error);
        if(user.error==false){
          this.$swal("Genial!",user.message+' '+user.data.user.name+'!', "success");
          this.$router.push({name: 'Home'});
          // this.$router.push({ path: '/' });
        }else
          this.$swal("Uppppssss!",user.message, "error");         
      }   
    },
    created(){ 
    }    
}
</script>